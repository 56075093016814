import {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Checkbox,
  Text,
  IconButton
} from '@chakra-ui/react'
import {cn, triggerDownload} from "app/helpers";
import {deleteNotes, downloadNotes, notesSelector} from "features/notes/notesSlice";
import ModulesFilter from "components/listing/ModulesFilter";
import Layout from "components/layout/Layout";
import Block from "components/layout/Block";
import PageHeading from "components/layout/PageHeading";
import FilterWrapper from "components/listing/FilterWrapper";
import Note from "components/notes/Note";
import DeleteIcon from "icons/DeleteIcon";
import DownloadIcon from "icons/DownloadIcon";
import ConfirmationModal from "components/modals/ConfirmationModal";
import {toast} from "react-toastify";
import {getCourseContent} from "features/course/courseContentSlice";
import EmptyList from "components/listing/EmptyList";
import NotesIcon from "icons/NotesIcon";

export default function NotesPage() {
  const dispatch = useDispatch();
  const classes = cn('notes-block');
  const header = {
    title: 'Notes',
  };
  const includeModules = [];
  const [selectedModules, setSelectedModules] = useState([]);
  const notes = useSelector(state => {
    const notes = notesSelector.selectAll(state);

    return notes.map(note => {
      const content = getCourseContent(state, note?.course_content?.id);
      if (content?.id) {
        const module = content?.module;
        if (module?.id && !includeModules.includes(module.id)) {
          includeModules.push(module.id);
        }
      }
      return {...note, module};
    })
      .filter(note => note && (selectedModules.length === 0 || selectedModules.includes(note?.module?.id)));
  });
  const [checkedNotes, setCheckedNotes] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const hasSelected = checkedNotes.length > 0;

  const handleFilter = (selected) => {
    setSelectedModules(selected);
  };

  const handleChange = (noteId, isSelected) => {
    if (isSelected) {
      setCheckedNotes([noteId, ...checkedNotes]);
    }
    else {
      setCheckedNotes(checkedNotes.filter(id => id !== noteId));
    }
  };

  const handleSelectAll = (e) => {
    const checkedNotes = [];
    if (e.target.checked) {
      notes.forEach((note) => {
        checkedNotes.push(note.id);
      });
    }
    setCheckedNotes(checkedNotes);
  };

  const handleDelete = () => {
    dispatch(deleteNotes(checkedNotes));
    setShowConfirm(false);
  }

  const handleDownload = () => {
    setDownloading(true);
    dispatch(downloadNotes(checkedNotes))
      .then(action => {
        if (action.meta.requestStatus === 'fulfilled') {
          triggerDownload(action.payload, 'ncsu-notes.pdf', 'application/pdf')
            .then(() => {
              setDownloading(false);
           })
            .catch(() => {
              setDownloading(false);
            });
        } else {
          setDownloading(false);
          const message = action.payload.message;
          if (message) {
            toast.error(message);
          }
        }

      });
  };

  useEffect(() => {
    const filteredNotes = checkedNotes.filter(id => notes.filter(note => note.id === id).length > 0);

    if (filteredNotes.length !== checkedNotes.length) {
      setCheckedNotes(filteredNotes);
    }
  }, [notes, checkedNotes]);

  return <Layout headerInfo={header}>
    <Block className={classes()}>
      <PageHeading title={header.title} isMain={true} size={'md'}>
        <FilterWrapper>
          {hasSelected ? <div className={classes('actions')}>
            <IconButton aria-label={'Delete'} variant={'outlineNavy'} icon={<DeleteIcon size={20} variant={'outline'} color={'navy'}/>} onClick={() => setShowConfirm(true)}/>
            <IconButton aria-label={'Download'} isLoading={downloading} variant={'outlineNavy'} icon={<DownloadIcon size={20} color={'navy'}/>} onClick={handleDownload}/>
          </div> :
            <ModulesFilter onChange={handleFilter} include={includeModules}/>}
          <Checkbox onChange={handleSelectAll}>Select All</Checkbox>
        </FilterWrapper>
      </PageHeading>
      {notes.length > 0 ? <div className={classes('list')}>
        {notes.map((note) => <Note key={note.id} id={note.id} isSelected={checkedNotes.includes(note.id)} onChange={handleChange}/>)}
      </div> : <EmptyList icon={<NotesIcon size={24} color={'navy'}/>} title={'It looks like you haven’t created any Notes yet.'}>
        <Text>Use the Notes Popup to make a note of anything you want to remember while working through the course.</Text>
      </EmptyList>}
      <ConfirmationModal isOpen={showConfirm} onConfirm={handleDelete} onCancel={() => {setShowConfirm(false)}} buttonText={'Delete'} buttonIcon={<DeleteIcon size={20} color={'white'} variant={'outline'}/>}>
        <Text>
          Are you sure you would like to delete this content? Once deleted it can not be recovered.
        </Text>
      </ConfirmationModal>
    </Block>
  </Layout>;
}
