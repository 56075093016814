import {useRegionData} from "../../features/block/blockHooks";
import {useSelector} from "react-redux";
import {getRegionState} from "../../features/block/blockSlice";
import Block from "./Block";
import CtaBlock from "./blocks/CtaBlock";
import {cn} from "../../app/helpers";
import IntroVideoBlock from './blocks/IntroVideoBlock';
import TextBlock from './blocks/TextBlock';

export default function Region({id}) {
  const classes = cn('region');
  const regionState = useSelector(state => getRegionState(state, id));
  const blocks = useRegionData(id);

  return blocks.length > 0 && <div className={classes({id: id})}>
    {regionState.isSuccess && blocks.map(block => {
      switch (block.content?.type) {
        case 'cta':
          return <CtaBlock key={block.id} data={block} />
        case 'intro_video':
          return <IntroVideoBlock key={block.id} data={block} />
        case 'text':
          return <TextBlock key={block.id} data={block} />
      }
      return <Block key={block.id} title={block.title} />
    })}
  </div>
}
